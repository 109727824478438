.AppInput{
    padding-right: 19px;

}
.sublinhar-vermelho .MuiOutlinedInput-root {
    border-color: red;
  }
  
  .sublinhar-vermelho .MuiInputLabel-root.Mui-focused {
    color: red;
  }
  
  .sublinhar-vermelho .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    color: red;
  }
  .mensagem-vermelha .MuiFormHelperText-root {
    color: red;
  }
    .selectComponet{
      margin: 2%;
    display: flex;
    }
    .btnBuscar{
      margin: 2%;
    }
    
  
  